<template>
  <div class="register-successful">
    <div class="register-successful__container">
      <img src="@/assets/icons/email.svg" alt="email"/>
      <h1>
        Confirm your email address
      </h1>
      <p class="text">Confirmation email has been sent to:</p>
      <p class="sub-text">
        {{ $store.state.auth.email }}
      </p>
      <p class="text">Not recieving the email?</p>
      <router-link
          type="primary"
          class="link"
          @click="submit"
          :loading="loading"
          to="/verifiaction-status"
      >
        Resend confirmation email
      </router-link>
      <p class="sub-text">check your junk folder</p>
    </div>
  </div>

</template>

<script>
import ApiService from "../../../services/api.service.js";

export default {
  data() {
    return {
      loading: false,
      message: {},
      showMessageModal: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      this.error = "";
      const requestObj = {
        email: this.$store.state.auth.email,
      };
      try {
        const response = await ApiService.post(
            "user/send-verification-email/",
            {email: requestObj.email}
        );
        if (response.status === 200) {
          this.showMessageModal = true;
          this.message = {
            title: "Email has been resend ",
            // desc: 'Thanks for using Glocal',
            type: "success",
          };
        }
      } catch (err) {
        this.message = {
          title: "Something went wrong",
          // desc: "Please Try Again later. Sorry for the inconvenience",
          type: "failure",
        };
        this.showMessageModal = true;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-successful {
  padding: 8rem 4rem;
  height: 100vh;
  background-color: #eceef1;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    margin: 0 auto;
    text-align: center;
    width: 54rem;
    background-color: $color-white;
    padding: 3rem;

    h1 {
      font-family: $font-secondary;
      font-size: 2.4rem;
      text-align: center;
      margin-bottom: 2rem;
      opacity: 0.7;
    }

    .text {
      font-family: $font-secondary-bold;
      font-size: 1.4rem;
      text-align: center;
      margin-bottom: 0;
    }

    .sub-text {
      font-family: $font-secondary;
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 3rem;
      opacity: 0.7;
    }

    .link {
      font-family: $font-secondary-bold;
      font-size: 1.6rem;
      color: $color-primary;
      padding: 0.8rem 0;
    }
  }
}
</style>
